@import "node_modules/bootstrap/less/bootstrap";
@import "node_modules/bootstrap-datepicker/less/datepicker3";

// @import "./spark/spark";
@import "./../../../vendor/laravel/spark/resources/assets/less/spark";

.form-inline{
	.input-group-btn{
		.btn{
			padding-top: 6px;
			padding-bottom: 7px;
		}
	}
}

.table{
	.actions{
		white-space: nowrap;
	}
}

.header-toggle{
	small:after{
		content: 'Hide'
	}
	&.collapsed{
		small:after{
			content: 'Show'
		}
	}
}

.sweet-alert{
	&.member-details{
		p{
			text-align: left;
		}
	}
}

.single-report{
	table{
		.active{
			font-weight: bold;
		}
	}
}

#revenueChart {
	width: 100%;
	height: 400px;
}